import React from 'react'
import ContactBanner from '../Components/ContactComponents/ContactBanner/ContactBanner'
import AnimatedRoutes from './AnimatedRoutes'

export default function Contact() {
  return (
   <>
   <AnimatedRoutes/>
   <ContactBanner/>
   </>
  )
}
