import React from 'react'
import CareerBanner from '../Components/CareerComponents/CareerBanner/CareerBanner'
import AnimatedRoutes from './AnimatedRoutes'

export default function Career() {
  return (
    <>
    <AnimatedRoutes/>
    <CareerBanner/>
    </>
  )
}
